import React, {useContext} from 'react';
import { ReportModalContext } from './ReportModal';
export default function ReportButton ({resourceId, resourceType}) {

    const [showReportModal, setShowReportModal, reportResourceType, setReportResourceType, reportResourceId, setReportResourceId] = useContext(ReportModalContext);

    const handleReportButtonClick = () => {
        setReportResourceId(resourceId);
        setReportResourceType(resourceType);
        setShowReportModal(true);
    }

    return (
    <button className='button-no-background' onClick={handleReportButtonClick}>
        <svg height="16" width="15">
            <line x1="2" y1="2" x2="2" y2="16" transform="rotate(-5)" style={{stroke:"red", strokeWidth:1}} />
            <rect x="4" y="2" width="8" height="6" transform="rotate(-5)" style={{fill:"red"}}/>
        </svg>
    </button>)
  }