import React, { useState, useEffect } from 'react';
import './AddCourse.css';
import InputField from '../components/common/InputField';
import RequiredStar from '../components/common/RequiredStar';
import { useHistory } from 'react-router-dom';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function AddCourse () {
    
    const history = useHistory();

    const redirectToCoursePage = courseId => {
        history.push(`/course/${courseId}`);
    }

    const redirectToHomePage = () => {
        history.push(`/`);
    }

    const [schoolResults, setSchoolResults] = useState([]);
    const [schoolName, setSchoolName] = useState();
    const [schoolId, setSchoolId] = useState();
    const [schoolIdValidation, setSchoolIdValidation] = useState("");

    const [courseName, setCourseName] = useState("");
    const [courseNameValidation, setCourseNameValidation] = useState("");
    const [schoolCourseId, setSchoolCourseId] = useState("");

    const onSchoolFocus = () => {
        document.getElementById("school-dropdown-add").style["display"] = "block";
    }

    const onSchoolBlur = () => {
        document.getElementById("school-dropdown-add").style["display"] = "none";
    }

    const handleSchoolInputChange = (event) => {
        setSchoolName( event.target.value );
        setSchoolId(undefined);
        if (event.target.value.length >= 1){
          getSchoolsBySearchTerm(event.target.value);
        }
    }

    const handleSchoolSelection = (event) => {
        setSchoolName( event.target.firstChild.nodeValue );
        setSchoolId( event.target.id );
        setSchoolIdValidation("");
    }

    const schoolDropdownItem = school => <div id={school.id} className="dropdown-option" onMouseDown={handleSchoolSelection}>{school.name}</div>

    const validateSchoolId = () => {
        if ( schoolId == undefined ) {
            setSchoolIdValidation("Must make a valid school selection");
            return false;
        }
        setSchoolIdValidation("");
        return true;
    }

    const validateCourseName = () => {
        if ( courseName.trim().length == 0 ) {
            setCourseNameValidation("Course name must not be empty");
            return false;
        }
        setCourseNameValidation("");
        return true;
    }

    const getSchoolsBySearchTerm = async searchTerm => {
        await axios({
            method: 'get',
            url: `${apiUri}/schools`,
            params: { searchTerm },
        }).then(
        (response) => {
            setSchoolResults( response.data.content );
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    const postCourse = async (data) => {
        await axios({
            method: 'post',
            url: `${apiUri}/courses`,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                if (response.data?.id) {
                    redirectToCoursePage(response.data.id);
                } else {
                    redirectToHomePage();
                }
            }, (error) => {
            }
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if ( !validateSchoolId() | !validateCourseName() ) return;   
        await postCourse({schoolId, name: courseName, schoolCourseId});
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<>
            <div className='coursey'>
                <div className='add-course-layout'>
                    <div className='top-container'>
                        <div className='content-container'>
                            <div className='top-flex'>
                                <div className='title-flex'>
                                    <div className='add-course-title'>Add Course</div>
                                </div>
                            </div>
                      </div>
                    </div>
                    <div className='content-container' style={{padding:'2rem'}}>
                        <form onSubmit={handleSubmit}>
                            <div className='input-flex'>
                                <label className='input-label'>School Name</label>
                                <RequiredStar/>
                            </div>
                            <br/>
                            <input 
                            onFocus={onSchoolFocus} 
                            onBlur={onSchoolBlur} 
                            value={schoolName} 
                            onChange={handleSchoolInputChange}
                            className="school-selector-dropdown-add" placeholder="Search for school..."/>
                            <div id="school-dropdown-add" className="dropdown-content-school-container">
                                { schoolResults.map(school => schoolDropdownItem(school)) }
                            </div>
                            {schoolIdValidation ? <><br/><span className='validation-message'>{schoolIdValidation}</span></> : <></>}
                            <br/><br/>
                            <InputField value={courseName} setValue={setCourseName} fieldName="courseName" label="Course Name" validation={courseNameValidation} required={true}/>
                            <InputField value={schoolCourseId} setValue={setSchoolCourseId} fieldName="schoolCourseId" label="Course ID" description={"University's shorthand to quickly identify a class. Ex: CSCI 1300"}/>
                            {courseName || schoolCourseId || schoolName ? 
                            <div className='preview-course'>
                                <div className='top-flex'>
                                    <div className='title-flex'>
                                        <div className='course-id'>{schoolCourseId}</div>
                                        <div className='course-name'>{courseName}</div>
                                    </div>
                                </div>
                                <div className='school-name'>{schoolName}</div>
                            </div> 
                            : <></>}
                            <button type='submit' id="submit-comment-button">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}