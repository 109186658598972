import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Home.css';
import BigButton from '../components/common/BigButton.js';

export default function Home () {

  const history = useHistory();

  const redirectToAddCourse = () => {
    history.push('/add-course');
  }

  const redirectToAddSchool = () => {
    history.push('/add-school');
  }

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [])

  return (<>
      <div id="title-with-image">
          <div id="title-flex">
            <div className="main-title-1">Class</div><div className="main-title-2">Rater</div>
          </div>
          <p className="sub-title-text">Identify the difficulty of a course before you take it</p>
      </div>
      <div className="desc-container-container hor-center">
        <div className="description-flex">
          <div style={{marginRight:'2rem'}}>
            <div className="simple-flex">
              <p className="description-1">Search</p><p className="description-1 highlight">hundreds of courses</p><p className="description-1">at your school</p>
            </div>
            <p className="description-2">Determine if the level of difficulty of a course is worth your time and effort. Write a review for courses you've already taken to assist others. Get started by finding a course at the top of your screen.</p>
          </div>
          <div className="database-container">
            <img src="database.svg" width="100px" height="100px"/>
          </div>
        </div>
      </div>
      <div className="ad-container"></div>
      <div className="use-background">
        <div className="use-container">
          <span className="contribute hor-center">Contribute!</span>
          <div className="use-grid">
            <div className="use-section">
              <div className="hor-center">
                <img src="education.svg" width="100px" height="100px"/>
              </div>
              <p>Believe we are missing a course?</p>
              <BigButton onClick={redirectToAddCourse}>Add a Course</BigButton>
            </div>
            <div className="use-section">
              <div className="hor-center">
                <img src="school.svg" width="100px" height="100px"/>
              </div>
              <p>Believe we are missing a school?</p>
              <BigButton onClick={redirectToAddSchool}>Add a School</BigButton>
            </div>
          </div>
          <span className='icon-credit'>Icons by geotatah and Good Ware from flaticon.com</span>
        </div>
      </div>
    </>
  );
}