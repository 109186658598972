import React, { useState } from 'react';
import './AddReview.css'
import InputField from './common/InputField';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function AddReview ({course, setShowAddReview}) {

    const [difficulty, setDifficulty] = useState("");
    const [grade, setGrade] = useState("");
    const [worth, setWorth] = useState("");
    const [hours, setHours] = useState("");

    const [difficultyValidation, setDifficultyValidation] = useState("");
    const [gradeValidation, setGradeValidation] = useState("");
    const [worthValidation, setWorthValidation] = useState("");
    const [hoursValidation, setHoursValidation] = useState("");

    const validateDifficulty = () => {
        let value = parseInt(difficulty);
        if ( !(typeof value == 'number' && value >= 1 && value <= 10) ) {
            setDifficultyValidation("Difficulty must be an integer between 1 and 10");
            return false;
        }
        setDifficultyValidation("");
        return true;
    }

    const validateGrade = () => {
        if (grade == "") {
            setGradeValidation("");
            return true;
        }
        let value = parseInt(grade);
        if ( !(typeof value == 'number' && value >= 0 && value <= 100) ) {
            setGradeValidation("Grade must be an integer between 0 and 100");
            return false;
        }
        setGradeValidation("");
        return true;
    }

    const validateWorth= () => {
        if (worth == "") {
            setWorthValidation("");
            return true;
        }
        let value = parseInt(worth);
        if ( !(typeof value == 'number' && value >= 0 && value <= 10) ) {
            setWorthValidation("Worth must be an integer between 0 and 10");
            return false;
        }
        setWorthValidation("");
        return true;
    }

    const validateHours= () => {
        if (hours == "") {
            setHoursValidation("");
            return true;
        }
        let value = parseInt(hours);
        if ( !(typeof value == 'number' && value >= 0 && value <= 40) ) {
            setHoursValidation("Hours must be an integer between 0 and 40");
            return false;
        }
        setHoursValidation("");
        return true;
    }

    const cancelForm = () => {
        setShowAddReview(false);
    }

    const submitReview = async (event) => {
        event.preventDefault();
        if ( !validateDifficulty() | !validateGrade() | !validateWorth() | !validateHours() ) return;
        await postReview({difficulty, grade, worth, hours});
        setShowAddReview(false);
    }

    const postReview = async (data) => {
        await axios({
            method: 'post',
            url: `${apiUri}/courses/${course.id}/reviews`,
            data
        }).then(
        (response) => {
            window.location.reload();
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    return (
        <form id="add-review-container" onSubmit={submitReview}>
            <InputField value={difficulty} required={true} setValue={setDifficulty} validation={difficultyValidation} fieldName="difficulty" label='Difficulty' description="Difficulty is on a scale from 1 to 10. 1 represents the course was very easy, while 10 represents very difficult."/>
            <InputField value={grade} setValue={setGrade} validation={gradeValidation} fieldName="grade" label="Grade received" description="Grade is on a scale from 0-100. (A+ = 100-97, A = 96-93, A- = 92-90, etc.)"/>
            <InputField value={worth} setValue={setWorth} validation={worthValidation} fieldName="worth" label="Worth" description="Worth is on a scale from 0-10. 0 meaning this course was pointless, do not bother taking it. 10 meaning this class saved my life."/>
            <InputField value={hours} setValue={setHours} validation={hoursValidation} fieldName="hours" label="Hours per week" description="Approximately how many hours spent on this course per week (including hours in classroom). Accepts only values between 0-40 hours."/>
            <div className='submit-flex'>
                <button type='cancel' id="cancel-review-button" onClick={cancelForm}>Cancel</button>
                <button type='submit' id="submit-review-button">Submit</button>
            </div>
        </form>
    );
  }