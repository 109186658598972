import React, { useState, useContext } from 'react';
import Home from './pages/Home.js'
import './App.css';
import axios from 'axios';
import { Route, Switch, Link, useHistory } from 'react-router-dom';
import Search from './pages/Search.js';
import IndividualCourse from './pages/IndividualCourse.js';
import ReportModal, { ReportModalContext } from './components/common/ReportModal.js';
import AddCourse from './pages/AddCourse.js';
import AddSchool from './pages/AddSchool.js';
import { apiUri } from './variables.js';

function App() {

  const [showReportModal, setShowReportModal] = useContext(ReportModalContext);

  const [schoolResults, setSchoolResults] = useState([]);
  const [schoolName, setSchoolName] = useState();
  const [schoolId, setSchoolId] = useState();

  const [courseResults, setCourseResults] = useState([]);
  const [courseSearchTerm, setCourseSearchTerm] = useState();

  const history = useHistory();

  // School

  const onSchoolFocus = () => {
    document.getElementById("school-dropdown").style["display"] = "block";
  }

  const onSchoolBlur = () => {
    document.getElementById("school-dropdown").style["display"] = "none";
  }
  
  const handleSchoolInputChange = async (event) => {
    setSchoolName( event.target.value );
    setSchoolId(undefined);
    if (event.target.value.length >= 1){
      getSchoolsBySearchTerm(event.target.value);
    }
  }

  const handleSchoolSelection = (event) => {
    setSchoolName( event.target.firstChild.nodeValue );
    setSchoolId( event.target.id );
    
  }

  const schoolDropdownItem = school => <div id={school.id} className="dropdown-option" onMouseDown={handleSchoolSelection}>{school.name}</div>

  // Course

  const onCourseFocus = (event) => {
    document.getElementById("course-dropdown").style["display"] = "block";
    let width = document.getElementById("course-search-input").offsetWidth;
    document.getElementById("course-dropdown").style.width = `${width}px`;
  }

  const onCourseBlur = () => {
    document.getElementById("course-dropdown").style["display"] = "none";
  }

  const handleCourseInputChange = (event) => {
    setCourseSearchTerm( event.target.value );
    if ( event.target.value.length >= 1 ) {
      schoolId ? 
      getCoursesBySearchTermAndSchoolId( event.target.value, schoolId)
       : getCoursesBySearchTermAndSchoolId( event.target.value, null);
    }
  }

  const handleCourseSearch = (event) => {
    document.getElementById("course-dropdown").style["display"] = "none";
    event.preventDefault();
    const schoolFilter = schoolId ? `schoolId=${schoolId}&` : '';
    const queryParams = schoolFilter + `searchTerm=${courseSearchTerm}`
    history.push(`/search?${queryParams}`);
  }

  const handleCourseSelection = (event) => {
    history.push(`/course/${event.target.id}`)
  }

  const courseDropdownItem = course => <div id={course.id} className="dropdown-option" onMouseDown={handleCourseSelection}>{course.schoolCourseId ? <>{course.schoolCourseId} {course.name}</> : <>{course.name}</>}</div>

  // API calls

  const getSchoolsBySearchTerm = async searchTerm => {
    await axios({
        method: 'get',
        url: `${apiUri}/schools`,
        params: { searchTerm },
    }).then(
    (response) => {
        setSchoolResults( response.data.content );
    }, (error) => {console.log("ruh roh", error.response)}
    );
  }

  const getCoursesBySearchTermAndSchoolId = async (searchTerm, schoolId) => {
    await axios({
        method: 'get',
        url: `${apiUri}/courses`,
        params: { searchTerm, schoolId },
    }).then(
    (response) => {
        setCourseResults( response.data.content );
    }, (error) => {console.log("ruh roh", error.response)}
    );
  }

  return (
    <main>
      {showReportModal ? <ReportModal/> : <> </>}
      <div className="background"/>
      <div className="top-nav">
        <Link to='/' className="logo">
          HOME
        </Link>
        <div className="school-dropdown-container">
          <input 
            onFocus={onSchoolFocus} 
            onBlur={onSchoolBlur} 
            value={schoolName} 
            onChange={handleSchoolInputChange}
            className="school-selector-dropdown" placeholder="Filter by school..."/>
          <div id="school-dropdown" className="dropdown-content-school-container">
            { schoolResults ? schoolResults.map(school => schoolDropdownItem(school)) : <></>}
          </div>
        </div>
        <form  id='course-search-input' className="course-search-container" onSubmit={handleCourseSearch}>
            <input 
            onFocus={onCourseFocus} 
            onBlur={onCourseBlur} 
            value={courseSearchTerm} 
            onChange={handleCourseInputChange}
            className="course-search" placeholder="Search for course..."/>
            <div id="course-dropdown" className="dropdown-content-course-container">
              { courseResults ? courseResults.map(course => courseDropdownItem(course)) : <></>}
            </div>
            <button type='submit' id="search-icon-button"><img src="search.svg" width="20" height="20"/></button>
        </form>
      </div>
      <div className="content-wrapper">
          <Switch>
              <Route path="/" component={Home} exact />
              <Route path="/add-course" component={AddCourse} />
              <Route path="/add-school" component={AddSchool} />
              <Route path="/search" component={Search} />
              <Route path="/course/:courseId" component={IndividualCourse} />
          </Switch>
      </div>
      <div className="footer">
        <div className='footer-text-1 hor-center'>For business inquiries or suggestions, email at</div>
        <div className='footer-text-2 hor-center'>classrater@gmail.com</div>
      </div>
    </main>
  );
}

export default App;
