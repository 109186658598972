import React from 'react';
import './BigButton.css';

export default function BigButton ({onClick, children}) {
    
    return (
      <button className="big-button" onClick={onClick}>
        {children}
      </button>
    );
  }