import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './Search.css';
import SearchResultCard from '../components/SearchResultCard';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function Search () {

    const [searchResults, setSearchResults] = useState([]);

    const getCoursesBySearchTermAndSchoolId = async (searchTerm, schoolId) => {
        await axios({
            method: 'get',
            url: `${apiUri}/courses`,
            params: { searchTerm, schoolId },
        }).then(
        (response) => {
            setSearchResults( response.data.content );
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const schoolId = params.get('schoolId');
        const searchTerm = params.get('searchTerm');
        if (schoolId && schoolId.length > 0){
            getCoursesBySearchTermAndSchoolId(searchTerm, schoolId);
        } else {
            getCoursesBySearchTermAndSchoolId(searchTerm, null);
        }
    }, [window.location.search])

    return (<>
            <div className='searchy'>
                <div className='search-layout'>
                    { searchResults.length > 0 ? searchResults.map( result => 
                        <SearchResultCard course={result}/>
                    ) : <div className='no-results'>No results</div>}
                </div>
            </div>
        </>
    );
}