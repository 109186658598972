import React from 'react';
import './Comment.css'
import ReportButton from './common/ReportButton';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function Comment ({courseId, comment, getComments}) {

    const putRating = async (data) => {
        await axios({
            method: 'put',
            url: `${apiUri}/courses/${courseId}/comments/${comment.id}`,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
        (response) => {
            window.sessionStorage.setItem(comment.id, true);
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    const handleRatingChange = async ratingChange => {
        if (window.sessionStorage.getItem(comment.id)) return;
        await putRating({ratingChange});
        getComments(courseId);
    }

    return (
         <div className='comment-container'>
             <div className='comment-title'>
                 {comment.title}
             </div>
             <div className='comment-content'>
                 {comment.content}
             </div>
             <div className='bottom-row-button-flex'>
                 <button className='button-no-background' onClick={() => handleRatingChange('up')}>
                    <svg height="10" width="20">
                        <line x1="3" y1="9" x2="10" y2="5" style={{stroke: "black", strokeWidth:'1'}} />
                        <line x1="10" y1="5" x2="17" y2="9" style={{stroke: "black", strokeWidth:'1'}}  />
                    </svg>
                 </button>
                 <span>{comment.rating}</span>
                 <button className='button-no-background' onClick={() => handleRatingChange('down')}>
                    <svg height="10" width="20">
                        <line x1="3" y1="5" x2="10" y2="9" style={{stroke: "black", strokeWidth:'1'}} />
                        <line x1="10" y1="9" x2="17" y2="5" style={{stroke: "black", strokeWidth:'1'}}  />
                    </svg>
                 </button>
                 <ReportButton resourceId={comment.id} resourceType={"Comment"}/>
             </div>
         </div>
    );
  }