import React from 'react';
import RequiredStar from './RequiredStar';
import './SelectableDropdown.css'
export default function SelectableDropdown ({value, setValue, fieldName, label, description}) {
    
    const handleValueInputChange = (event) => {
        setValue( event.target.value );
    }

    return (
      <div className='input-container'>
        <label htmlFor={fieldName} className='input-label'>{label}</label><RequiredStar/><br/>
        <div className='input-description'>{description}</div>
        <select id={fieldName} name={fieldName} value={value} onChange={handleValueInputChange} className="selectable-dropdown">
            <option value="other">Other</option>
            <option value="hate">Racism / Hate speech</option>
            <option value="duplicate">Duplicate</option>
            <option value="spam">Spam</option>
            <option value="spelling">Spelling</option>
        </select>
      </div>
    );
  }