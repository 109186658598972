import React, { useState, useEffect } from 'react';
import './AddSchool.css';

export default function AddCourse () {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (<>
            <div className='coursey'>
                <div className='add-course-layout'>
                    <div className='top-container'>
                        <div className='content-container'>
                            <div className='top-flex'>
                                <div className='title-flex'>
                                    <div className='add-course-title'>Add School</div>
                                </div>
                            </div>
                      </div>
                    </div>
                    <div className='content-container' style={{padding:'2rem'}}>
                       Please email us at classrater@gmail.com if you believe we are missing your school or university
                    </div>
                </div>
            </div>
        </>
    );
}