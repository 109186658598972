import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import './IndividualCourse.css';
import Comment from '../components/Comment';
import AddReview from '../components/AddReview';
import AddComment from '../components/AddComment';
import ReportButton from '../components/common/ReportButton';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function IndividualCourse (props) {

    const params = useParams();
    const [course, setCourse] = useState({});
    const [comments, setComments] = useState([]);
    const [showAddReview, setShowAddReview] = useState(false);
    const [showAddComment, setShowAddComment] = useState(false);

    const ColorGenerator = (min, max, value) => {
        if ( value == '') return "#ffffff";
        value = parseFloat(value);
        if (value < min && value < max) return "#ff0000";
        const total = Math.abs(max - min);
    
        let greenValue;
        let redValue;
    
        if (max > min) {
            value = Math.abs(value-min);
            const hexValue = Math.round(15 - (Math.abs(value - total/2) * 15) / (total/2) ).toString(16);
            if (value >= total/2) { //green
                greenValue = "ff";
                redValue = "" + hexValue + hexValue;
            } else { // red
                redValue = "ff";
                greenValue = "" + hexValue + hexValue;
            }
        } else { // max < min
            value = Math.abs(value-max);
            const hexValue = Math.round(15 - (Math.abs(value - total/2) * 15) / (total/2) ).toString(16);
            if (value <= total/2) { //green
                greenValue = "ff";
                redValue = "" + hexValue + hexValue;
            } else { // red
                redValue = "ff";
                greenValue = "" + hexValue + hexValue;
            }
        }
    
        let color = "#" + redValue + greenValue + "00";
        return color;
    }

    const handleAddReviewClick = () => {
        setShowAddComment(false);
        setShowAddReview(true);
    }

    const handleAddCommentClick = () => {
        setShowAddReview(false);
        setShowAddComment(true);
    }

    const getCourseById = async courseId => {
        await axios({
            method: 'get',
            url: `${apiUri}/courses/${courseId}`,
        }).then(
        (response) => {
            setCourse( response.data.content );
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    const getComments = async courseId => {
        await axios({
            method: 'get',
            url: `${apiUri}/courses/${courseId}/comments`,
        }).then(
        (response) => {
            setComments( response.data.content.sort((a, b) => (a.rating <= b.rating) ? 1 : -1) );
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }
    
    useEffect(() => {
        window.scrollTo(0, 0);
        getCourseById(params.courseId);
        getComments(params.courseId);
    }, [params.courseId])

    return (<>
            <div className='coursey'>
                <div className='course-layout'>
                    <div className='top-container'>
                        <div className='content-container'>
                            <div className='top-flex'>
                                <div className='title-flex'>
                                    <div className='course-id'>{course ? course.schoolCourseId : <></>}</div>
                                    <div className='course-name'>{course ? course.name : <></>}</div>
                                </div>
                                <ReportButton resourceId={course ? course.id : <></>} resourceType={"Course"}/>
                            </div>
                            <div className='school-name'>{course ? course.schoolName : <></>}</div>
                            <div className='review-container'>
                                <div className='review-flex'>
                                    <div className='roboto hor-center review-text'>
                                        Difficulty
                                    </div>
                                    <div className='fraction-container review-flex'>
                                        {course && course.difficulty ? 
                                            <div style={{color:ColorGenerator(10, 1, course.difficulty)}} className='review-actual-value'>
                                                {course.difficulty}
                                            </div> : 
                                            <div style={{color:ColorGenerator(10, 1, '')}} className='review-actual-value'>
                                                {'-'}
                                            </div> 
                                        }
                                        <div className='review-max-value'>
                                            /10
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div className='review-flex'>
                                    <div className='roboto hor-center review-text'>
                                        Average Grade
                                    </div>
                                    <div className='fraction-container review-flex'>
                                        {course && course.grade ? 
                                            <div style={{color:ColorGenerator(60, 100, course.grade)}} className='review-actual-value'>
                                                {course.grade}
                                            </div> : 
                                            <div style={{color:ColorGenerator(60, 100, '')}} className='review-actual-value'>
                                                {'-'}
                                            </div> 
                                        }
                                        <div className='review-max-value'>
                                            /100
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div className='review-flex'>
                                    <div className='roboto hor-center review-text'>
                                        Worth
                                    </div>
                                    <div className='fraction-container review-flex'>
                                        {course && course.worth ? 
                                            <div style={{color:ColorGenerator(0, 10, course.worth)}} className='review-actual-value'>
                                                {course.worth}
                                            </div> : 
                                            <div style={{color:ColorGenerator(0, 10, '')}} className='review-actual-value'>
                                                {'-'}
                                            </div> 
                                        }
                                        <div className='review-max-value'>
                                            /10
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div className='review-flex'>
                                    <div className='roboto hor-center review-text'>
                                        Hours per week
                                    </div>
                                    <div className='fraction-container review-flex'>
                                        {course && course.hours ? 
                                            <div style={{color:ColorGenerator(40, 0, course.hours)}} className='review-actual-value'>
                                                {course.hours}
                                            </div> : 
                                            <div style={{color:ColorGenerator(40, 0, '')}} className='review-actual-value'>
                                                {'-'}
                                            </div> 
                                        }
                                        <div className='review-max-value'>
                                            /40
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='button-flex'>
                                <button className='roboto add-button' onClick={handleAddReviewClick}>
                                    Add review
                                </button>
                                <button className='roboto add-button' onClick={handleAddCommentClick}>
                                    Comment
                                </button>
                            </div>
                            {showAddReview ? <AddReview course={course} setShowAddReview={setShowAddReview}/> : <></>}
                            {showAddComment ? <AddComment course={course} setShowAddComment={setShowAddComment}/> : <></>}
                      </div>
                    </div>
                    <div className='content-container' style={{padding:'2rem'}}>
                        {comments.length > 0 ? comments.map(comment => {
                            return (<Comment courseId={course.id} comment={comment} getComments={getComments}/>)
                        }) : <div id='no-comment'>No comments</div>}
                    </div>
                </div>
            </div>
        </>
    );
}