import React from 'react';
import './SearchResultCard.css'
import Underline from './common/Underline';
import { useHistory } from 'react-router-dom';

export default function SearchResultCard ({course}) {
    
    const history = useHistory();

    const redirectToCourse = (course) => {
        history.push(`/course/${course.id}`);
    }

    return (
         <div className='search-result-container' onClick={() => {redirectToCourse(course)}}>
             <div className='school-name-container'>{course.schoolName}</div>
             <Underline/>
             <div className='course-section-flex'>
                <div className='course-id-container'>{course.schoolCourseId}</div>
                <div className='course-name-container'>{course.name}</div>
             </div>
         </div>
    );
  }