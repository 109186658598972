import React, { useState } from 'react';
import './AddComment.css'
import InputField from './common/InputField';
import RequiredStar from './common/RequiredStar';
import { apiUri } from '../variables.js';
import axios from 'axios';

export default function AddComment ({course, setShowAddComment}) {

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    const [titleValidation, setTitleValidation] = useState("");
    const [contentValidation, setContentValidation] = useState("");

    const handleContentChange = (event) => {
        setContent( event.target.value );
    }

    const validateTitle = () => {
        if ( title.length == 0 ) {
            setTitleValidation("Title must not be empty");
            return false;
        }
        setTitleValidation("");
        return true;
    }

    const validateContent = () => {
        if ( content.length == 0 ) {
            setContentValidation("Content must not be empty");
            return false;
        }
        setContentValidation("");
        return true;
    }

    const cancelForm = () => {
        setShowAddComment(false);
    }

    const submitComment = async (event) => {
        event.preventDefault();
        if ( !validateTitle() | !validateContent() ) return;   
        await postComment({title, content});
        setShowAddComment(false);
    }

    const postComment = async (data) => {
        await axios({
            method: 'post',
            url: `${apiUri}/courses/${course.id}/comments`,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
        (response) => {
            window.location.reload();
        }, (error) => {console.log("ruh roh", error.response)}
        );
    }

    return (
        <form id="add-comment-container" onSubmit={submitComment}>
            <InputField value={title} required={true} setValue={setTitle} validation={titleValidation} fieldName="title" label='Title'/>
            <div className='comment-input-container'>
                <label className='comment-label' htmlFor='content'>Content</label><RequiredStar/><br/>
                <textarea className='comment-text-area' name='description' value={content} onChange={handleContentChange} maxLength={500} />
                {contentValidation ? <><br/><span className='validation-message'>{contentValidation}</span></> : <></>}
            </div>
            <div className='submit-flex'>
                <button type='cancel' id="cancel-comment-button" onClick={cancelForm}>Cancel</button>
                <button type='submit' id="submit-comment-button">Submit</button>
            </div>
        </form>
    );
  }