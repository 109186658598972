import React from 'react';
import './InputField.css';
import RequiredStar from './RequiredStar';

export default function InputField ({value, setValue, fieldName, label, placeholder, description, validation, required}) {
    
    const handleValueInputChange = (event) => {
        setValue( event.target.value );
    }

    return (
      <div className='input-container'>
        <div className='input-flex'>
          <label htmlFor={fieldName} className='input-label'>{label}</label> <br/>
          {required ? <RequiredStar/> : <></>}
        </div>
        <div className='input-description'>{description}</div>
        <input type="text" id={fieldName} name={fieldName} value={value} onChange={handleValueInputChange} className="input-field" placeholder={placeholder}/>
        {validation ? <><br/><span className='validation-message'>{validation}</span></> : <></>}
      </div>
    );
  }