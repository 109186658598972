import React, { useState, createContext, useContext, useEffect } from 'react';
import './ReportModal.css';
import RequiredStar from './RequiredStar';
import SelectableDropdown from './SelectableDropdown';
import { apiUri } from '../../variables.js';
import axios from 'axios';

export default function ReportModal () {

    const [showReportModal, setShowReportModal, reportResourceType, setReportResourceType, reportResourceId, setReportResourceId] = useContext(ReportModalContext);
    const [reason, setReason] = useState("other");
    const [description, setDescription] = useState("");
    const [descriptionValidation, setDescriptionValidation] = useState("");

    const closeModal = () => {
        setReportResourceId(null);
        setReportResourceType(null);
        setShowReportModal(false);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const validateDescription = () => {
        if ( description.length == 0 ) {
            setDescriptionValidation("Description must not be empty");
            return false;
        }
        setDescriptionValidation("");
        return true;
    }

    const postReport = async data => {
        await axios({
            method: 'post',
            url: `${apiUri}/reports`,
            data: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(
            (response) => {
                closeModal();
            }, (error) => {
            }
        );
    }

    const submitReport = async (event) => {
        event.preventDefault();
        if ( !validateDescription() ) return;
        await postReport({resourceId: reportResourceId, resourceType: reportResourceType, reason, description});
    }

    return (<div id='modal-dark-backdrop'>
                <form id="modal" onSubmit={submitReport}>
                    <div id="modal-title">Report {reportResourceType}</div>
                    <SelectableDropdown value={reason} setValue={setReason} fieldName="reason" label="Reason"/>
                    <div className='comment-input-container'>
                        <label className='comment-label' htmlFor='description'>Description</label><RequiredStar/><br/>
                        <textarea className='comment-text-area' name='description' value={description} onChange={handleDescriptionChange} maxLength={255} />
                        {descriptionValidation ? <><br/><span className='validation-message'>{descriptionValidation}</span></> : <></>}
                    </div>
                    <div className='submit-flex'>
                        <button type='cancel' id="cancel-comment-button" onClick={closeModal}>Cancel</button>
                        <button type='submit' id="submit-comment-button">Submit</button>
                    </div>
                </form>
            </div> );
  }

export const ReportModalContext = createContext();

export const ReportModalProvider = (props) => {
    const [showReportModal, setShowReportModal] = useState(false);
    const [reportResourceType, setReportResourceType] = useState(false);
    const [reportResourceId, setReportResourceId] = useState(false);
    return <ReportModalContext.Provider value={[showReportModal, setShowReportModal, reportResourceType, setReportResourceType, reportResourceId, setReportResourceId]}>
            {props.children}
        </ReportModalContext.Provider>
}
